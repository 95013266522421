@import "src/_variables.scss";

.timeline-step-container {
  .timeline-step {
    &__title {
      display: block;
      padding: 50px 0 30px;
      font: 40px/44px $PSB;
      font-style: normal;
      text-align: center;
      color: $dark-blue;
      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }
      &__text {
        padding: 10px 0;
      }
      .timeline_step-subtitle {
        display: block;
        &__text {
          font: 20px/32px $PR;
          color: $grey;
          max-width: 510px;
          @media (max-width: 1440px) {
            font: 18px/28px $PR;
          }
        }
      }
    }
    &__content {
      width: 350px;
      margin: auto;
      .icon-button-wrapper .icon-button {
        max-width: 150px;
      }
      .criteria-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
      }
      .criteria-column {
        display: flex;
        flex-direction: column;
        flex: 2;
        padding: 0 8px 16px;
        .icon-button-wrapper .icon-button {
          width: 150px;
          justify-content: center;
          position: relative;
        }
      }
    }
  }
}
