@import "src/_variables.scss";

.welcome-step-container {
  //max-width: 70%;
  width: 60%;

  .welcome-step {
    &__image {
      margin: 0 auto 20px;
      // width: 511px;
      height: 128px;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    &__title {
      padding: 15px 0;
      font: 40px/44px $PSB;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: $dark-blue;
      max-width: 900px;
      margin: 0 auto;

      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }
    }
  }
}
