@import "src/_variables.scss";

.top-bar-container {
  align-items: center;
  background: $white;
  border-radius: 24px;
  box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
  min-height: 100px;
  padding: 30px 30px 10px 64px;

  &.is_fixed {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .return-button {
    display: flex;
    font: 16px $PR;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
    cursor: pointer;
    padding-bottom: 10px;
    .ant-btn {
      border: none;
      box-shadow: none;
      padding: 0;
    }
    .button__text {
      border: none;
      display: flex;
      color: $grey;
    }
    &__icon {
      padding-right: 10px;
      fill: $grey;
    }
    .ant-btn:hover {
      text-decoration: none;
      border: none;
    }
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    .top-bar__left {
      display: flex;

      .house-info-container {
        .house-address {
          padding-left: 16px;
          font: 22px $PSB;
          color: #1B235E;
        }

        .house-main-info-container {
          display: flex;
          flex-wrap: wrap;
          padding-left: 11px;

          .property {
            font: 14px/17px $PSB;
            padding: 0 10px; // 0 6px
            margin: auto 0;

            &__type {
              color: $grey;
              padding-left: 15px;
            }
          }

          .card-tag {
            font: 16px $PSB;
            margin: 5px;
            width: auto;
          }

          .days {
            padding-left: 10px;
            font: 14px/16px $PSB;
            color: $grey2;
            margin: auto 0;

            > span {
              display: flex;
              align-items: center;
            }

            svg {
              min-width: 18px;
              width: 18px;
              margin-right: 5px;
              fill: $grey2;
            }
          }
        }
      }
    }

    .top-bar__right {
      display: flex;
      justify-content: flex-end;

      .tour-btn-top-bar {
        margin: auto 10px;
        max-width: 160px;
      }

      .favorites-btn {
        margin: auto 0;
      }
    }
  }
}
