@import "src/_variables.scss";

.ant-modal-mask {
  background: rgba(27, 35, 94, 0.2) !important;
  backdrop-filter: blur(4px);
}

.criteria-modal {
  max-width: 566px !important;

  .ant-modal-content {
    border-radius: 24px;
  }

  .modal-close {
    .ant-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      background: $background;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      border: none;
      padding: 17px;
      height: auto;
      line-height: 0;

      svg {
        fill: $grey;
        width: 10px;
        height: 10px;
      }
    }
  }

  .modal-title-container {
    justify-content: center;
    margin: auto;
    padding: 15px 0;

    &__title {
      padding: 5px 0;
      font: 32px/36px $PSB;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: $dark-blue;
    }

    &__subtitle {
      padding: 5px 0;
      text-align: center;
      font: 16px/19px $PR;
      color: $grey;
    }
  }

  .criteria-modal-body {
    padding: 20px 23px 0 23px;
    margin: auto;

    .ant-form-item-control-input-content {
      text-align: center;
    }

    .ant-checkbox-group {
      display: inline-grid;
      text-align: left;
      white-space: nowrap;

      .checkbox-wrapper {
        padding: 5px;
      }

      .ant-checkbox {
        top: 0;
      }
    }

    .select-range-field__inputs .input-container {
      width: 266px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 13px;

    .cancel-button {
      width: 190px;
      padding: 10px;

      .ant-btn {
        border: none;
        width: 100%;
        height: 100%;
        color: $grey2;
        font: 18px/24px $PB;
        padding: 16px;
        border-radius: 16px;
        cursor: pointer;
        transition: all 0.3s;
        background: $grey-light2;

        &:after {
          display: none;
        }

        &:disabled {
          background: $grey-light;
        }

        .button__text {
          font: 16px/20px $PB;
        }
      }
    }

    .save-button {
      width: 190px;
      padding: 10px;

      .ant-btn {
        background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        border-radius: 16px;
        color: $white;
        width: 100%;
        padding: 15px;
        height: auto;
        border: none;
        transition: all .4s ease-in-out;

        &:hover,
        &:focus {
          background-size: 300% 100%;
          background-position: 0 100%;
          background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
          border: none;
          color: $white;
        }

        &:after {
          display: none;
        }

        &:disabled {
          background: $grey-light;
        }

        .button__text {
          font: 16px/20px $PB;
        }

        &:disabled {
          cursor: default;
          background: $grey-light;
          color: $grey2;
        }
      }
    }
  }

  .distance-fields {

    .switcher {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 40px;
      box-shadow: none;
      background: #F4F7FC;
      width: 100%;

      .switch-button {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0;

        svg {
          margin: 0;
          width: 16px;
          min-width: 16px;
          display: block;
        }

        .button__text {
          margin-left: 4px;
          font-size: 10px;
          white-space: normal;
          display: block;
          line-height: 1.1;
          text-align: left;
          opacity: 0;
        }

        &.active {
          flex: 3;

          .button__text {
            transition-delay: 0.2s;
            opacity: 1;
          }
        }
      }
    }
  }

  .select-range-field__selects .ant-select {
    min-width: 0;
  }
}