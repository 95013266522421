@import "src/_variables.scss";

.details-map {
  display: flex;
  height: 450px;
  margin-bottom: 22px;

  .map-view-wrapper {
    padding-top: 0;
    margin-top: 0;
    .google-container-wrapper {
      margin-top: 0;
      .google-container {
        border-radius: 0;
        .place-info-card {
          height: 53px;
          background: $white;
          box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
          display: flex;
          width: 100%;
          padding: 10px 8px;
          align-items: center;
        }
      }
    }
  }
}
