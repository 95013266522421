@import "src/_variables.scss";

.card-view-wrapper {
	padding-bottom: 10px;

	.card-view {
		background: $white;
		border-radius: 16px;
		cursor: pointer;

		.status {
			display: flex;
			align-items: center;
			padding-bottom: 11px;

			&-item {
				font: 12px $PSB;
				padding-right: 16px;
			}

			.status-days__container {
				display: flex;

				.message {
					font: 12px $PSB;
					color: #A9A9C4;
					padding-left: 5px;
				}
			}
		}

		&__photo {
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			overflow: hidden;

			.ant-image {
				display: block;
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				overflow: hidden;
			}

			img {
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		&__score-inner {
			position: relative;
			height: auto;
		}

		&__score {
			position: absolute;
			z-index: 1;
			top: 15px;
			left: 15px;

			.score-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 60px;
				width: 60px;
				border-radius: 29px;
				font-size: 24px;
				font-weight: bold;
				font-style: normal;
				font-family: "Proxima Nova Regular";
			}

		}

		&__bottom-content {
			padding: 16px 16px 10px;

			.address {
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				margin-bottom: 8px;
				align-items: center;

				&-content {
					padding-right: 20px;
					line-height: 1.25;
				}
			}
		}
	}
}

.card-tag {
	border-radius: 8px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	margin: 5px 6px 0;
	font-weight: 700;
	min-height: 28px;
	padding: 0 8px;
	min-width: 72px;
	width: calc(25% - 12px);
	text-align: center;
	line-height: 1.29;
	white-space: break-spaces;
}



.accordion {
	margin: 0 -6px;

	&:not(:last-child) {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid blue;
	}

	&__arrow {
		width: 12px;
		min-width: 12px;
		margin: 0 auto;
		color: #A0A0A0;

		svg {
			transition: 0.3s;
		}
	}

	&__text {
		display: flex;
	}

	&__icon {
		width: 20px;
		min-width: 20px;
		height: 20px;
		margin-right: 4px;
	}

	&__title {
		font-weight: 500;
		font-size: 14px;
	}

	&__block {
		display: none;
		font-size: 12px;
	}

	&.is-active {
		.accordion__arrow {

			svg {
				transform: rotate(180deg);
			}

		}

		.accordion__block {
			display: flex;
			flex-wrap: wrap;
		}
	}
}