@import "src/variables";

.sign-up-form {
  display: flex;
  flex-direction: column;

  &__bottom {
    margin-top: 100px;
  }

  &__user-name {
    display: flex;
    gap: 4%;
    .ant-form-item {
      width: 48%;
    }
  }
}
