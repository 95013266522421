@import "src/_variables.scss";

.property-info-container {
  padding: 32px;
  //background: white;

  .property-info {
    &__details {
      &__criteria-info {
        padding: 0 30px;
        .criteria-info-wrapper {
          display: flex;
          padding: 8px 0;
          .criteria-icon-wrapper {
            min-width: 28px;
            height: 28px;
            background: $white;
            box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
            border-radius: 50%;
          }
          .criteria-icon {
              display: flex;
              margin: auto;
              width: 16px;
              height: 100%;
          }
          .criteria-description {
            display: block;
            padding: 0 12px;
            .criteria-title {
              color: $grey3;
              font: 10px/14px $PSB;
              padding-bottom: 2px;
            }
            .criteria-value {
              color: $dark-blue;
              font: 14px $PSB;
            }
          }
        }
      }
      &__description {
        font: 16px $PR;
        color: $dark-blue;
        padding: 0 32px 32px;
        .description-title {
          font: 22px/27px $PSB;
          font-style: normal;
          padding: 20px 0 16px;
        }
      }
      &__open-house-times {
        background: $white;
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        max-width: 332px;
        max-height: 292px;
        padding: 32px;
        margin: 25px auto 0;

        .open-houses-separator-line {
          background: $grey-light;
          height: 1px;
          margin: 24px 0;
        }
        .schedule-description {
          &__content {
            display: block;
            &__text {
              color: $dark-blue;
              font: 16px/140% $PR;
              font-style: normal;
              font-weight: 400;
            }
          }
          &__title {
            color: $dark-blue;
            font: 16px $PSB;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 16px;
          }

        }
        .schedule-button {
          &__title {
            color: $dark-blue;
            font: 16px $PSB;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 16px;
          }
          .tour-btn-description {
            .ant-btn {
              width: 100%;
              height: 52px;
            }
          }
        }
      }
    }
    &__photos {
      padding-bottom: 20px;
    }
  }
}
