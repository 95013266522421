@import './src/variables';

.houses-main-wrapper {
  position: relative;
  display: flex;

  .main {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;

    .main-content {
      display: flex;
    }

    .main-header {
      display: flex;
      align-items: center;
      margin-top: 45px;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;

      &__title {
        font: 22px/27px $PB;
        color: $dark-blue;
        margin-bottom: 30px;
      }

      &__results {
        padding-left: 24px;
        padding-top: 5px;
        font: 16px $PSB;
        color: #908DB9;
      }

      &__left {
        padding-right: 20px;
      }
    }
  }
}

.switcher {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 290px;
  height: 60px;
  border-radius: 60px;
  background: $white;
  box-shadow: inset 0 2px 4px rgba(149, 113, 246, 0.15);


  .switch-button {
    width: 129px;
    height: 44px;
    border-radius: 24px;
    background: $white;
    border: none;
    box-shadow: none;
    font: 14px $PSB;
    color: $dark-blue;

    &:hover,
    &:focus,
    &:active {
      color: $dark-blue;
    }

    svg {
      fill: $dark-blue;
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  .switch-button.active {
    background: linear-gradient(90deg, #9571F6 0%, #583EF5 100%);
    box-shadow: 0 4px 16px 5px rgba(88, 62, 245, 0.13);
    color: $background;

    &:hover,
    &:focus,
    &:active {
      color: $background;
    }


    svg {
      fill: $background;
    }
  }
}

.list-view-wrapper {
  max-width: 100%;
  width: 100%;
}

.list-view-wrapper {
  margin-top: -35px; // 20px
}

.favorites-title {
  margin: 110px 40px 0;
  font: 22px/27px "Proxima Nova Bold";
}

.list-view {
  min-height: 400px;

  .ant-spin-nested-loading {
    height: 100%;
  }

  .hiscore-column {
    display: flex;
    align-items: center;
    width: 405px; // 364px
    min-width: 364px;

    &__score {
      margin-right: 20px;

      .score-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        width: 52px;
        border-radius: 29px;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-family: "Proxima Nova Regular";
      }
    }

    &__house {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      min-width: 0;

      .house-info {
        padding-left: 15px;
        padding-right: 15px;

        &__address {
          display: grid;
          font: 14px/17px $PB;
          color: $dark-blue;
          padding-bottom: 12px;
          transition: 0.3s;
        }

        &__status {
          display: flex;
          align-items: center;

          &-days {
            padding-left: 10px;
            font: 12px/16px $PSB;
            color: $grey2;

            >span {
              display: flex;
              align-items: center;
            }

            svg {
              min-width: 13px;
              width: 13px;
              margin-right: 5px;
              fill: $grey2;
            }
          }

          .status-item {
            font: 12px/16px $PSB;
            text-transform: capitalize;
          }
        }
      }
    }

    &:hover {
      .house-info__address {
        color: #9571F6;
      }
    }
  }
}

.list-house {
  height: calc(100vh - 240px);
  overflow-y: scroll;

  .favorites .favorites-button {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: static;
    }
  }
}

.list-house-pagination {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 50;
  padding-right: 80px;

  .ant-pagination {
    display: flex;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);

    .ant-pagination-item-link {
      background: $white;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      border: none;
      font-size: 16px;
      width: 44px;
      height: 44px;
      min-width: 44px;

      .anticon svg {
        fill: $violet;
      }

      &:disabled {
        .anticon svg {
          fill: $grey3;
        }
      }
    }
  }

  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    width: 44px;
    height: 44px;
    min-width: 44px;


    a {
      color: $dark-blue;
      font: 18px $PSB;
    }
  }

  .ant-pagination-item-active a {
    color: $violet;
  }
}

.favorites {
  .favorites-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: $white;
    border: 1px solid $grey-light;

    .ant-btn:hover,
    .ant-btn:focus {
      border: 1px solid $grey-light;
    }

    svg {
      position: absolute;
      left: 30.12%;
      top: 32.44%;
      fill: $violet-light;
    }
  }

  .active {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);

    &:focus,
    &:hover {
      background: linear-gradient(90deg, #9571F6 0%, #583EF5 100%);
    }

    svg {
      fill: $white;
    }
  }
}

.ant-table {
  overflow: hidden;
  border-radius: 24px;

  .ant-table-column-title {
    flex: 0 0;
  }
}

.list-view__table {
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 5;
  }
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody>tr>td {
    border: none;
  }

  .ant-table-tbody>tr {
    cursor: pointer;

    &:nth-of-type(even) td {
      background: #FAFAFF;
    }
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 10px 16px;
  }

  .ant-table-thead>tr>th {
    font: 14px/17px $PSB;
    color: $grey;
    border-bottom: none;
    background: $white;
    white-space: nowrap;
  }


  .ant-table-column-sorter {
    color: $grey;
    margin-left: 12px;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-sorters:hover .ant-table-column-sorter,
  .ant-table-column-sorters:focus .ant-table-column-sorter {
    color: $grey;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: darken($grey, 10%)
  }

  .ant-table-column-sorter-up {
    display: none;
  }

  .ant-table-column-sorter-up.active {
    display: block;
    margin-top: 11px;
  }

  .ant-table-column-sorter-up+.ant-table-column-sorter-down {
    margin-top: 11px;
  }

  .ant-table-column-sorter-up.active+.ant-table-column-sorter-down {
    display: none;
  }


  .ant-table-tbody>tr {
    height: 112px;
    min-height: 112px;
  }


  .ant-table-container table>thead>tr:first-child th:first-child {
    position: sticky;
    z-index: 5;
    left: 0;
  }


  .ant-table-tbody>tr td:first-child {
    position: sticky;
    z-index: 5;
    left: 0;
  }

  .ant-table-thead>tr>th:first-child {
    background: #FFFFFF;
    box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
    z-index: 3;
  }

  .ant-table-thead>tr>th {
    background: #FFFFFF;
  }

  .ant-table-tbody>tr:nth-of-type(even) td:first-child {
    z-index: 1;
    background: #FAFAFF;
    box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
  }

  .ant-table-tbody>tr:nth-of-type(odd) td:first-child {
    background: #FFFFFF;
    z-index: 1;
    box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
  }

  .ant-table-tbody>tr:nth-of-type(even) td {
    background: #FAFAFF;
  }

  .ant-table-tbody>tr:nth-of-type(odd) td {
    background: #FFFFFF;
  }

  // .ant-table-container {
  //   margin-left: 436px;
  // }
}

.houses {
  position: relative;

  .houses-header {
    position: absolute;
    padding-right: 10px;
    right: 0;
    z-index: 2;
  }

  .house-content {
    margin-top: 100px; // 45px
  }
}

.list-content {
  margin: 0 !important;
  padding-left: 20px;
  padding-right: 20px;
}
.empty-button{
  .ant-btn {
            background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
            color: $white;
            border-radius: 16px;
            font: 16px $PB;
            padding: 0;
            border: none;
            height: auto;
            transition: all 0.3s;

            svg {
              fill: $white;
              margin-left: 16px;
            }

            &:hover,
            &:focus {
              background-size: 300% 100%;
              background-position: 0 100%;
              background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
              color: $white;
            }

            &:disabled {
              background: $grey-light2;
              color: $grey2;
              cursor: default;

              svg {
                fill: $grey2;
              }
            }

            .button__text {
              padding: 20px 44px;
            }
          }
}
