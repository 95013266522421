@import "src/_variables.scss";

.filters-content {
  position: relative;
  height: 100%;

  .ant-form {
    // padding-bottom: 60px;
    // padding-bottom: 20px;
  }
}


.btn-default-position {
  margin-top: 60px;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.btn-default {
 text-align: right;

  .ant-btn {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    border-radius: 16px;
    font: 16px/20px $PB;
    color: $white;
    border: none;
    padding: 16px 24px;
    height: auto;

    &:hover,
    &:focus {
      color: $white;
      background-size: 300% 100%;
      background-position: 0 100%;
      background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    }
  }
}

.filters-close {
  .ant-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: $background;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    border: none;
    padding: 17px;
    height: auto;
    line-height: 0;
    z-index: 1;

    &:hover,
    &:focus {
      background: $background;
    }

    svg {
      fill: $grey;
      width: 10px;
      height: 10px;
    }
  }
}

.form-checkbox {
  &.ant-form-item {
    margin-top: 24px;
    margin-left: 32px;
  }

  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    color: $dark-blue;
    font: 14px/17px $PSB;
    margin-bottom: 16px;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background: $background;
      box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
      border-radius: 8px;
      border: none;

      input[type='checkbox'] {
        display: none;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 12px;
    background: url("/images/icon-checkbox.svg") no-repeat center/100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-checkbox-checked::after {
    display: none;
  }
}

.lot-size-form {
  .MuiSlider-markLabel {
    display: none;
  }
  .select-range-field__inputs {
    margin-top: 0;
  }
}

.password-validation {
  font: 14px/17px $PSB;
  color: $red;
  display: flex;
  &__icon {
    padding-right: 16px;
    padding-bottom: 12px;
  }
  &.validation-success {
    color: $green;
  }
}

.profile-form-columns {
  display: grid;
  justify-content: space-around;
  grid-template-columns: 45% 45%;

  &__email {
    display: block;
    .ant-form-item {
      margin-right: -24px;
    }
    .ant-input-affix-wrapper {
    background: #f4f7fc !important;
    border: none;

    .ant-input {
      background: #f4f7fc !important;
    }
  }
  }
  &__password {
    display: block;
    &.is_confirm_opened {
      .ant-form-item {
        margin-right: -24px;
      }

      .ant-input-suffix {
        margin-right: 24px;
      }
    }
    .save_password {
      .ant-btn {
        .button__text {
          font: 16px/20px $PB;
        }
      }
    }
  }
}

.distance-form {
  .custom-slider-wrapper {
    padding: 0;
  }
}

.inverted-slider {
  .MuiSlider-thumb[data-index='0'] {
    background: url("/images/icon-star.svg") no-repeat center/ 16px 16px transparent !important;

    &:before {
      box-shadow: none;
    }
    &::after {
      box-shadow: none;
      width: 16px;
      height: 16px;
      background: url("/images/icon-star.svg") no-repeat center/ 16px 16px transparent !important;
      border: none;
    }
  }

  .MuiSlider-thumb[data-index='2'],
  .MuiSlider-thumb[data-index='1'] {
    width: 12px;
    height: 12px;


    &::after {
      width: 12px;
      height: 12px;
      background: $white;
      border: 1px solid #6044F5;
      box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.13);
    }
  }
}