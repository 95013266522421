@import './src/variables';

.house-details-wrapper {
  width: 100%;
  height: 100%;
  padding: 30px;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .not-found-page {
    margin-top: 35px;
  }
  .house-details-container {
    background: $white;
    border-radius: 24px;

    .separator-line {
      background: $grey-light;
      margin: 20px 60px;
      height: 1px;
    }

    .house-details-other-details {
      &__tour {
        &__text {
          padding-top: 15px;
          padding-left: 32px;
          text-align: left;
          font:16px $PR;
          color: #1B235E;
        }
        .button-schedule-a-tour {
          padding-left: 32px;
          padding-top: 2%;
          .ant-btn {
            background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
            color: $white;
            border-radius: 16px;
            font: 16px $PB;
            padding: 0;
            border: none;
            height: auto;
            transition: all 0.3s;

            svg {
              fill: $white;
              margin-left: 16px;
            }

            &:hover,
            &:focus {
              background-size: 300% 100%;
              background-position: 0 100%;
              background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
              color: $white;
            }
            .status-item {
              text-align: end;
              font: 16px $PSB;
              text-transform: capitalize;
            }

            &:disabled {
              background: $grey-light2;
              color: $grey2;
              cursor: default;

              svg {
                fill: $grey2;
              }
            }

            .button__text {
              padding: 20px 44px;
            }
          }


        }
      }
    }
  }
}
.react-images__view-image,
.ant-image-preview-img{
  height: 80vh;
}

.details-collapse {
  margin-top: 20px;
  background: white;
  border-radius: 24px;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse {
    background: white;
    border-radius: 24px;
    border-bottom: none !important;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse-header {
    padding-left: 30px !important;
    padding-top: 30px !important;
    padding-bottom: 20px !important;
    font: 16px $PSB;
    color: #1B235E;
  }

  .ant-collapse-content {
    overflow-y: auto;
    max-height: 300px;
          &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 0.17);

      }

      &::-webkit-scrollbar-thumb {
        background: #D0D1E1;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #9593F4;
      }
  }

  .ant-collapse-arrow {
    right: 40px !important;

    svg {
      fill: #1B235E;
    }
  }
}

.other-details-wrapper {
  .other-detail:nth-child(odd) {
    background: #FAFAFF;
  }

  .other-detail:last-child {
    border-radius: 24px;
  }

  .other-detail {
    display: flex;
    align-items: center;

    &__title {
      font: 16px $PR;
      color: #1B235E;
      padding: 12px 30px;
      flex: 1;
      min-width: 0;
    }

    &__values {
      display: flex;
      font: 16px $PSB;
      color: #1B235E;
      flex: 1;
      min-width: 0;

      .value-message {
        white-space: break-spaces;
        padding: 6px;
      }
    }
  }

  .broker-card {
    display: grid;
    justify-content: left;
    align-items: center;
    margin: 20px;
    border-radius: 24px;
    padding-left: 10px;
    box-shadow: 0px 4px 4px 0px rgba(147, 157, 244, 0.17);

    &__name {
      padding-top: 15px;
      text-align: left;
      font:16px $PR;
      color: #1B235E;
    }
    &__subtitle {
      padding-top: 10px;
      text-align: left;
      font:16px $PR;
      color: #1B235E;
    }
    &__type {
      padding-top: 15px;
      text-align: left;
      font:16px $PR;
      color: #1B235E;
    }
    &__phone {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-top: 15px;
      padding-bottom: 30px;
      text-align: center;
      font:16px $PR;
      color: #1B235E;

      // &-number {
      //   padding-left: 10px;
      // }
    }
  }

}

.details-tag {
  	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}
