@import "src/_variables.scss";

.login {
  padding: 32px;
  display: flex;
  background: url('/images/bg-login.svg') no-repeat center/cover $background;
  min-height: 100vh;

  &__left {
    position: relative;
    width: 55%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .image-people {
      position: absolute;
      display: block;
      margin: auto;
      width: 100%;
      height: calc(100% - 32px);
      bottom: -32px;
    }

    .icon-landing {
      position: absolute;
      background: $dark-blue;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding-top: 15px;
      padding-bottom: 5px;
      bottom: 32px;
      left: 32px;
      width: 263px;
      text-align: center;
      z-index: 5;

      svg {}
    }
  }

  &__right {
    position: relative;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 32px;
    background: $white;
    width: 45%;
    padding: 64px 70px;
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;

    svg {
      fill: $dark-blue;
    }
  }

  &__form {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__bottom {
      margin-top: 24px;
    }

    .form-item {
        flex-grow: 1;
    }
  }

  &__google {
    width: 100%;
    color: $blue;
    font: 18px/24px $PB;
    padding: 16px;
    background: $white;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:focus {
      color: darken($blue, 20%);
    }

    .icon-google {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      vertical-align: middle;
    }
  }

  &__or {
    display: block;
    text-align: center;
    font: 20px/28px $PSB;
    color: $grey;
    margin: 12px auto;
  }

  .forgot-password {
    display: block;
    text-align: right;
    color: $violet-light;
    font: 16px/19px $PSB;
    margin-top: -10px;

    &:hover,
    &:focus {
      color: $violet;
    }
  }

  .ant-input-affix-wrapper {
    background: $background;
    border-radius: 12px;
    font: 16px/19px $PSB;
    color: $dark-blue;
    padding: 16px 24px;
    border: none;

    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    input.ant-input {
      font: 16px/19px $PSB;
      color: $dark-blue;
      background: $background;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $background inset !important;
      }

      &:focus,
      &:hover {
        background: $background;
      }
    }
  }

  .ant-form-item-has-error {

    & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: $background;
      border: none;
      box-shadow: none;
    }
  }

  &__continue {
    width: 100%;

    .ant-btn {
      background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      border-radius: 16px;
      color: $white;
      width: 100%;
      padding: 15px;
      height: auto;
      border: none;
      transition: all .4s ease-in-out;

      &:hover,
      &:focus {
        background-size: 300% 100%;
        background-position: 0 100%;
        background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        border: none;
        color: $white;
      }

      &:after {
        display: none;
      }

      &:disabled {
        background: $grey-light;
      }

      .button__text {
        font: 18px/22px $PB;
      }

      svg {
        fill: $white;
        margin-left: 16px;
        width: 16px;
        height: auto;
      }

      &:disabled {
        cursor: default;
        background: $grey-light;
        color: $grey2;

        svg {
          fill: $grey2;
        }
      }
    }
  }

  &__sign-up {
    text-align: center;
    font: 16px/19px $PSB;
    color: $dark;
    margin-bottom: 25px;

    a {
      color: $violet-light;
      margin-left: 5px;

      &:hover,
      &:focus {
        color: $violet;
      }
    }
  }
  
  &__error-message {
    position: absolute;
    display: block;
    padding: 12px 30px;
    width: 100%;
    font: 16px/20px $PB;
    color: $white;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $red;
    border-radius: 32px 32px 0 0;

    &__icon {
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-right: 13px;
    }

    .ant-btn {
      position: absolute;
      background: transparent;
      width: 13px;
      height: 13px;
      border: none;
      padding: 0;
      right: 24px;
      top: 15px;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }

      svg {
        fill: $white;
      }
    }
  }
}