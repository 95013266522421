
.map-view-wrapper {
  width: 100%;
  height: 100%;
  min-height: 450px;
  padding-top: 15px;
  margin-top: -55px;
  .ant-spin-nested-loading {
    top: 50%;
  }
}

.google-container {
  width: 100%;
  height: 100%;
  border-radius: 16px;

  .map-marker-container {
    position: absolute;
    top: -48px;
    left: -58px;
    border-radius: 17px;
    cursor: pointer;
  }

  .gm-style-iw-a {
    margin-top: -50px;
  }

  .gm-style-iw.gm-style-iw-c {
    border-radius: 16px;
    padding: 0;
    .gm-style-iw-d {
      overflow: inherit !important;
    }
    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  div {
    &:focus {
      outline: none;
    }
  }
}

.map-view {
  display: flex;
  height: 100%;
  .google-container-wrapper {
    position: relative;
    width: 100%;
    margin-top: 65px;
  }

  .list-house-container {
    // padding-left: 20px;
    width: 425px;
    min-width: 425px;

    .list-house-header {
      display: flex;
      cursor: pointer;
      align-items: center;

      &__sorting {
        display: flex;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        background: #EDEDF2;
        height: 33px;
        width: 120px;
        border-radius: 16px;
        color: #637AFF;

        &-icon {
          transition: 0.3s;
          display: inline-flex;

          &.is-active {
            transform: rotate(180deg);
          }
        }
      }
    }

    .ant-divider-horizontal {
      margin-bottom: 7px;
    }
  }
}