@import './src/variables';

.empty-top-matches {
  display: block;
  background: white;
  margin: 14px 40px 0;
  border-radius: 24px;
  width: calc(100% - 80px);


  &__messages {
    margin: 0 auto;
    padding-top: 50px;
    width: fit-content;
    justify-content: center;

    .empty-button {
      padding-top: 10px;
      padding-right: 15px;
    }

    &-title {
      display: flex;
      justify-content: center;
      font: 24px/28px $PB;
      color: #1B235E;
      padding-bottom: 12px;
    }

    &-description {
      white-space: break-spaces;
      text-align: center;
      font: 16px/24px $PR;
      color: #1B235E;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    img {
      max-width: 50vw;
    }
    &.withBottomSpace {
      padding-bottom: 30px;
    }
  }
}
